import React from "react"
import { Router, Link, Location } from "@reach/router"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import {
  withAuthenticator,
  SignIn,
  ConfirmSignIn,
  RequireNewPassword,
  ForgotPassword,
  Loading,
  Greetings,
  TOTPSetup,
  VerifyContact,
  SignOut,
} from "aws-amplify-react"
import Amplify, { Auth, Storage } from "aws-amplify"
import Iframe from 'react-iframe'
import Head from 'react-helmet';

import "./font.css"

const AWS_COGNITO_USER_POOL_ID = process.env.AWS_COGNITO_USER_POOL_ID
const AWS_COGNITO_IDENTITY_POOL_ID = process.env.AWS_COGNITO_IDENTITY_POOL_ID
const AWS_COGNITO_WEB_CLIENT_ID = process.env.AWS_COGNITO_WEB_CLIENT_ID
const ARI_DENTAL_FORMS_BUCKET = process.env.ARI_DENTAL_FORMS_BUCKET

Amplify.configure({
  Auth: {
    identityPoolId: AWS_COGNITO_IDENTITY_POOL_ID,
    region: "us-east-1",
    userPoolId: AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: AWS_COGNITO_WEB_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: ARI_DENTAL_FORMS_BUCKET,
      region: "us-east-1",
    },
  },
})

const Redirect = ({ url }) => {
  React.useEffect(() => {
    window.location.replace(url)
  })
  return <div />
}

const App = () => {
  return (
    <React.Fragment>
      <Head>
        <title>Viewer</title>
        <meta name="robots" content="noindex" />
      </Head>
      <FadeTransitionRouter>
        <Page path="d/:documentId" />
        <Redirect default url={"https://ari.dental"} />
      </FadeTransitionRouter>
    </React.Fragment>
  )
}

const FadeTransitionRouter = props => (
  <Location>
    {({ location }) => (
      <Router location={location} className="router">
        {props.children}
      </Router>
    )}
  </Location>
)

const Page = withAuthenticator(
  ({ documentId }) => {
    const [isLoading, setLoading] = React.useState(true);
    const [signedUrl, setSignedUrl] = React.useState('');

   React.useEffect(() => {
      ;(async function() {
        const result = await Storage.get(`${documentId}.pdf`);
        setSignedUrl(result);
        setTimeout(() => {
          setLoading(false)
        }, 2000)
      })()
    }, [])

    if (isLoading) {
      return (
        null
      )
    } 

    return (
      <Iframe url={signedUrl}
        width="100%"
        height="100%"
        display="flex"
        position="relative" />
    )
  },
  true,
  [
    <Greetings />,
    <SignIn />,
    <ConfirmSignIn />,
    <RequireNewPassword />,
    <VerifyContact />,
    <ForgotPassword />,
    <TOTPSetup />,
    <Loading />,
  ],
  null,
  {
    a: {
      color: "#3C8AEF",
      textDecoration: "none",
    },
    formSection: {},
    button: {
      borderRadius: 5,
      backgroundColor: "#3C8AEF",
    },
    navButton: {
      borderRadius: 5,
      backgroundColor: "#3C8AEF",
    },
    hint: {
      marginTop: "1.5em",
    },
    input: {
      borderRadius: 5,
      "&:focus": {
        boxShadow: "0 0 3px rgba(60, 137, 239, 0.7) !important",
        border: "1px solid rgba(60, 137, 239, 0.7) !important",
      },
    },
  }
)

export default App
